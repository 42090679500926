import React, {useEffect, useState } from "react";

import TurnLivreOnboardingAudio from "../../../../component/Audio/TurnLivreOnboardingaudio";
import TurnLivre from "../TurnLivre";

const Onboarding = ({setmenuVisibleHome, menuVisibleHome}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageFlipAudio, setCurrentPageFlipAudio] = useState(1);
  const {orientationPicture} = "verticale";  

  const [load, setLoad] = useState(false);



  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoad(true)
    }, 1500);
  
    return () => clearTimeout(timeout); // Annule le timeout si le composant se démonte avant
  }, []);

  return (
    <>
        <TurnLivre 
        setLoad={setLoad}
             onPageChange={handlePageChange}
             CurrentPageFlipAudio={currentPageFlipAudio}
             orientationPicture={orientationPicture}
             setmenuVisibleHome={setmenuVisibleHome}
             menuVisibleHome={menuVisibleHome}
        />
   {  load &&   <TurnLivreOnboardingAudio
          pageNumber={currentPage}
          setCurrentPageFlipAudio={setCurrentPageFlipAudio}
        />}
    </>
  );
};

export default Onboarding;
