import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../helpers/AuthContext";
import { apiUrl } from "../config";

function Books() {
  const { authState, setAuthState } = useContext(AuthContext);
  const [livres, setLivres] = useState([]);
  const [showHorizontal, setShowHorizontal] = useState(true);
  const [orientationPicture, setorientationPicture] = useState("carte");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1); // Ajout de la page pour la pagination
  const history = useHistory();
  const booksPerPage = 5; // Nombre de livres chargés à la fois

  useEffect(() => {
    setAuthState((prevState) => ({ ...prevState, loading: true }));
  }, [setAuthState]);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history.push("/login");
    }
  }, [history]);

  // Fonction de chargement des livres
  const fetchBooks = async () => {
    try {
      setAuthState((prevState) => ({ ...prevState, loading: true }));

      const startNum = showHorizontal ? 1 : 101;
      const endNum = showHorizontal ? 100 : 200;
      const maxPages = Math.ceil((endNum - startNum + 1) / booksPerPage);

      if (page > maxPages) {
        setHasMore(false);
        setAuthState((prevState) => ({ ...prevState, loading: false }));
        return;
      }

      const fetchedCartes = [];
      const start = startNum + (page - 1) * booksPerPage;
      const end = Math.min(start + booksPerPage - 1, endNum);

      for (let num = start; num <= end; num++) {
        const response = await axios.get(
          `${apiUrl}/postimages/lireimagespresentation/${authState.id}/${num}`,
          { headers: { accessToken: localStorage.getItem("accessToken") } }
        );

        if (response.data && response.data.length > 0) {
          fetchedCartes.push(response.data[0]);
        }
      }

      setLivres((prevLivres) => [...prevLivres, ...fetchedCartes]);
      setPage((prevPage) => prevPage + 1);
      setHasMore(fetchedCartes.length === booksPerPage); 
      setAuthState((prevState) => ({ ...prevState, loading: false }));
    } catch (err) {
      console.error("Échec de la récupération des livres :", err);
      setAuthState((prevState) => ({ ...prevState, loading: false })); // Correction ici
    }
  };

  useEffect(() => {
    if (authState.id) {
      setLivres([]); // Vider avant de charger
      setPage(1); // Réinitialiser la page
      setHasMore(true); // Permettre le chargement
      fetchBooks();
    }
  }, [authState.id, showHorizontal]);

  const handleCardClick = (number) => {
    history.push("/Monlivre", { number, orientationPicture });
    setAuthState((prevState) => ({
      ...prevState,
      bibli: true,
      identity: true,
      accueil: true,
      create: true,
    }));
  };

  const toggleDisplay = () => {
    setShowHorizontal((prev) => !prev);
    setLivres([]); // Réinitialise la liste des livres
    setPage(1); // Redémarre la pagination
    setHasMore(true); // Assure que le chargement peut se poursuivre
  };

  useEffect(() => {
    setorientationPicture(showHorizontal ? "carte" : "cartehorizontale");
  }, [showHorizontal]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
        hasMore
      ) {
        fetchBooks();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore]);

  return (
    <>
      <button onClick={toggleDisplay} className="boutonchoix">
        <div className="emoji-container">
          <svg width="100%" height="100%" viewBox="0 0 100 100">
            <defs>
              <path id="textCircle" d="M 10,50 A 40,40 0 1,1 90,50" />
            </defs>
            <text fill="white" fontWeight="bold">
              <textPath href="#textCircle" textAnchor="middle" startOffset="50%">
                {showHorizontal ? "Horizontal" : "Vertical"}
              </textPath>
            </text>
          </svg>
          <span className="emoji">{showHorizontal ? "🌍" : "🌕"}</span>
        </div>
      </button>

      <div className="calquesdeschoix">
        {livres.map((livre, index) => (
          <div
            key={index}
            className={orientationPicture}
            onClick={() => handleCardClick(livre.numberofdeck)}
          >
            {livre.lien ? (
              <>
                <img src={livre.lien} alt={livre.numberofdeck} />
                <p>{livre.title}</p>
              </>
            ) : (
              <p>{livre.title}</p>
            )}
          </div>
        ))}

{hasMore && livres.length > 0 && (
  <button onClick={fetchBooks} className="load-more">
    Charger plus de livres
  </button>
)}
      </div>
    </>
  );
}

export default Books;
